
import "./Table.scss";
import SubPeriodicalTable from "./SubPeriodicalTable.vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import { scheduleService } from "@/shared/services/api/scheduled_workitem";
import moment from "moment";
import moment_tz from "moment-timezone";
import {
  tableTitle,
  SCHEDULE_STATUS,
  periodicSubTabHeader,
} from "@/shared/interface/scheduledItem";
import { WORKITEM_JOB_TYPE, SCHEDULE_TYPE } from "@/shared/interface/newItem";
import { WORKITEM_FILTER_CRITERIA } from "@/shared/interface/workItem";
import { Auth } from "@/shared/services/auth";
import { commonService } from "@/shared/services/api/common";

@Component({
  name: "TableComponent",
  components: {
    SubPeriodicalTable,
  },
})
export default class TableComponent extends Vue {
  @Prop({ default: SCHEDULE_TYPE.once }) scheduledType;
  @Prop({ default: () => tableTitle() }) tableHeader;
  @Prop({ default: () => [] }) scheduleStatus;
  @Prop({ default: () => [] }) tableType;

  loading = false;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  total = 0;
  tableKey = "work-item-";
  tableData: any = [];
  timezoneSettings = {
    currentTimezone: moment_tz.tz.guess(),
    currentTimezoneOffset: Math.floor(moment().utcOffset() / 60),
  };
  scheduleSuccess: SCHEDULE_STATUS = SCHEDULE_STATUS.ScheduleSuccess;
  scheduledState: SCHEDULE_STATUS = SCHEDULE_STATUS.Scheduled;
  scheduledEnums = SCHEDULE_TYPE;
  periodicSubTabHeader = periodicSubTabHeader();

  @Watch("scheduledType", { deep: true, immediate: true })
  toggleWorkJobType(): void {
    this.initFetch();
  }

  @Watch("$store.state.currentApp", { deep: true, immediate: true })
  onCurrentAppChange(): void {
    this.initFetch();
  }

  @Watch("$store.state.isRefetchingScheduleList", {
    deep: true,
    immediate: true,
  })
  refetchData(val: boolean): void {
    if (val) {
      this.getData();
    }
  }

  /**
   * init fetch data query
   */
  initFetch(): void {
    this.currentPage = 0;
    this.total = 0;
    this.offset = 0;
    this.getData();
  }

  /**
   * Skip to the Case Details page
   * @params {id}: case id
   * @params {executionId}: for periodically scheduled job, only joint work_item_id and executionId to define execution table
   * @params {serviceId}: when job is not request rundeck,execution_id hadn't generated yet, use service_id to message topic for rtc logging
   * @returns void
   */
  gotoDetail(id = "", executionId = "", serviceId = ""): void {
    let defaultRoute = `/detail?id=${id}&jobType=${WORKITEM_JOB_TYPE.scheduled}&scheduledType=${this.scheduledType}&newTab=true`;

    if (executionId) {
      defaultRoute += `&executionId=${executionId}`;
    }

    if (serviceId) {
      defaultRoute += `&serviceId=${serviceId}`;
    }

    const route = this.$router.resolve({ path: defaultRoute });
    window.open(route.href);

    // this["$router"].push(defaultRoute)
  }

  /**
   * Retrieve data based on the current page number
   * @params {currentPage}: current page
   * @returns void
   */
  currentPageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.offset = (currentPage - 1) * this.pageSize;
    this.getData();
  }

  /**
   * cancel scheduled WorkItem
   * @param {string} scheduleId: the schedule_id of WorkItem
   * @returns void
   */
  handleCancel(scheduleId: string): void {
    this.loading = true;
    scheduleService
      .cancelScheduledWorkItem(this.scheduledType, scheduleId)
      .then((res) => {
        if (!res.data || res.data.code !== "1") {
          const msg = res.data?.msg || "";
          this.handleCancelError(msg);
          return;
        }
        this.$message.success("Cancel scheduled work item successfully.");
        if (this.currentPage > 1 && this.offset + 1 === this.total) {
          // handle last page with only one record
          this.currentPage -= 1;
          this.offset = (this.currentPage - 1) * this.pageSize;
        }
        this.$store.commit("setRefetchingScheduleList", true);
      })
      .catch((error) => {
        const status = error?.networkError?.statusCode;
        if (status === 401) {
          location.reload();
        }
        const msg = error.response?.data?.message || "";
        this.handleCancelError(msg);
      });
  }

  /**
   * hanle the cancel schedule error
   * @param {string} msg: the error message from API
   * @returns void
   */
  handleCancelError(msg: string): void {
    this.$message({
      message: "Cancel scheduled work item failed. " + msg,
      type: "error",
      duration: 6000,
    });
    this.loading = false;
  }

  /**
   * get data
   * @returns void
   */
  getData(): void {
    this.loading = true;

    const productList: string[] = Auth.getProductFilters().map(
      (product) => product?.value
    );
    const params: WORKITEM_FILTER_CRITERIA = {
      status: this.scheduleStatus,
      scheduledType: this.scheduledType,
      offset: this.offset,
      pageSize: this.pageSize,
      owner: [this.$store.state.userId],
      app_name: this.$store.state.currentApp.name,
      product: productList,
    };
    tsGraphQLService
      .getScheduledJobs(params)
      .then(
        (res) => {
          let data = res.data;
          if (data?.listByPage?.length > 0) {
            data.listByPage.forEach((item, index) => {
              this.$set(data.listByPage, index, {
                ...data.listByPage[index],
                start_time: item.start_time
                  ? moment(item.start_time).format("YYYY-MM-DD HH:mm:ss")
                  : "",
              });

              if (item.hybris_workspace_item) {
                this.$set(data.listByPage, index, {
                  ...data.listByPage[index],
                  id: item.hybris_workspace_item.id,
                });
              }

              if (
                item?.hybris_workspace_item?.hybris_workspace_item_jobs?.length
              ) {
                this.$set(data.listByPage, index, {
                  ...data.listByPage[index],
                  content:
                    item.hybris_workspace_item.hybris_workspace_item_jobs[0]
                      .hybris_nb_cio_job.job_name,
                  product: item.hybris_workspace_item.product,
                });
              }
            });
            this.tableData = data.listByPage;
          } else {
            this.tableData = [];
          }
          this.total = data?.list?.aggregate?.count || 0;
        },
        (error) => {
          const status = error?.networkError?.statusCode;
          if (status === 401) {
            location.reload();
          }
        }
      )
      .finally(() => {
        this.loading = false;
        this.$store.commit("setRefetchingScheduleList", false);
      });
  }

  handleCollpaseIcon({ row }: { row: any }): string {
    return row?.cx_iops_job_schedule_periodic?.length > 0
      ? ""
      : "collapse-icon__hidden";
  }
}
